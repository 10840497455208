<template>
	<Modal
		:title="title"
		:value="visible"
		width="700"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>

		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="耗材名称:" prop="drugSupplyTypeName">
						<Input
							v-model="formData.drugSupplyTypeName"
							placeholder="请输入耗材名称"
							:disabled="true"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="耗材编号:" prop="drugSupplyNo">
						<Input
							v-model="formData.drugSupplyNo"
							placeholder="请输入药品编号"
						/>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="数量:" prop="amount">
						<Input
							v-model="formData.amount"
							placeholder="请输入数量"
							:disabled="true"
						/>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="批号:" prop="batchNo">
						<Input v-model="formData.batchNo" placeholder="请输入批号" />
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="有效期:" prop="validityDate">
						<DatePicker
							type="date"
							placeholder="请输入有效期"
							format="yyyy-MM-dd"
							v-model="formData.validityDate"
							@on-change="v => (formData.validityDate = v)"
							style="width: 100%"
						></DatePicker>
					</FormItem>
				</Col>

				<Col span="12">
					<FormItem label="快递单号:" prop="expressNo">
						<Input v-model="formData.expressNo" placeholder="请输入快递单号" />
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="12">
					<FormItem label="快递单:" prop="expressFileId">
						<p class="file-name" v-if="formData.expressFileName">
							{{ formData.expressFileName }}
							<span class="delete-file" @click="deleteFile('expressFileId')"
								>&times;</span
							>
						</p>
						<Upload
							action="/"
							v-else
							:show-upload-list="false"
							:before-upload="file => getMD5(file, 'expressFileId')"
							style="display: inline-block"
						>
							<Button>上传文件</Button>
						</Upload>
					</FormItem>
				</Col>
				<Col span="12">
					<FormItem label="运送温度记录:" prop="temperatureFileId">
						<p class="file-name" v-if="formData.temperatureFileName">
							{{ formData.temperatureFileName }}
							<span class="delete-file" @click="deleteFile('temperatureFileId')"
								>&times;</span
							>
						</p>
						<Upload
							action="/"
							v-else
							:show-upload-list="false"
							:before-upload="file => getMD5(file, 'temperatureFileId')"
							style="display: inline-block"
						>
							<Button>上传文件</Button>
						</Upload>
					</FormItem>
				</Col>
			</Row>
		</Form>
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/inventory/meterial"
import { formDataToMD5 } from "@/utils/util"

const { apiUpdate, apiGet, uploadFile } = api

export default {
	name: "Add",
	props: ["visible", "typeList", "updateId"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "修改批次",
			rules: {
				supplyName: {
					required: true,
					message: "药品名称不能为空",
					trigger: "blur"
				},
				drugSupplyNo: {
					required: true,
					message: "药品编号不能为空",
					trigger: "blur"
				},
				batchNo: { required: true, message: "批号不能为空", trigger: "blur" },
				amount: [
					{ required: true, message: "药物数量不能为空", trigger: "blur" },
					{
						...this.$rules.positiveInteger,
						message: "药物数量必须为正整数",
						trigger: "blur"
					}
				]
			}
		}
	},
	watch: {
		visible() {
			if (this.updateId) {
				this.$asyncDo(async () => {
					this.loading = true
					const res = await apiGet({
						projectId: this.projectId,
						drugSupplyTypeId: this.$route.params.drugId,
						id: this.updateId
					})
					if (res) {
						this.formData = res.data
						this.formData.amount = String(this.formData.amount)
					}
					this.loading = false
				})
			}
		}
	},
	computed: {
		projectId() {
			return this.$route.params.projectId || this.$route.query.projectId
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		onOk() {
			this.$refs.form.validate(valid => {
				if (valid) {
					this.$asyncDo(async () => {
						this.loading = true
						let res = false
						if (this.updateId) {
							res = await apiUpdate({
								projectId: this.projectId,
								drugSupplyTypeId: this.$route.params.drugId,
								...this.formData,
								id: this.updateId
							})
						}
						if (res) {
							this.$emit("onOk")
							this.$Message.success("操作成功！")
						}
						this.loading = false
					})
				}
			})
		},
		// 删除上传的文件
		deleteFile(type) {
			const name = type.replace("Id", "Name")
			this.$set(this.formData, type, "")
			this.$set(this.formData, name, "")
		},
		// md5
		getMD5(file, type) {
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, type) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					const name = type.replace("Id", "Name")
					this.$set(this.formData, type, res.data)
					this.$set(this.formData, name, file.name)
				}
			})
		}
	}
}
</script>
<style scoped lang="less">
.file-name {
	position: relative;
	line-height: 20px;
	height: 20px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	.delete-file {
		color: #fff;
		background-color: #999;
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		width: 20px;
		height: 20px;
		z-index: 2;
		text-align: center;
		border-radius: 50%;
		font-size: 14px;
		cursor: pointer;
		&:hover {
			background-color: red;
		}
	}
}
</style>
