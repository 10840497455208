import request from "@/utils/request"

export default {
	// 获取列表
	apiGetPage: data =>
		request(
			`/project/${data.projectId}/drug-supply-type/${data.drugSupplyTypeId}/drug-supply`,
			{
				params: data
			}
		),
	// 新增
	apiAdd: data =>
		request(
			`/project/${data.projectId}/drug-supply-type/${data.drugSupplyTypeId}/drug-supply`,
			{
				method: "post",
				body: data
			}
		),
	// 获取详情
	apiGet: data =>
		request(
			`/project/${data.projectId}/drug-supply-type/${data.drugSupplyTypeId}/drug-supply/${data.id}`,
			{
				params: data
			}
		),
	// 修改
	apiUpdate: data =>
		request(
			`/project/${data.projectId}/drug-supply-type/${data.drugSupplyTypeId}/drug-supply/${data.id}`,
			{
				method: "put",
				body: data
			}
		),
	// 回收
	apiCallBack: data =>
		request(
			`/project/${data.projectId}/drug-supply-type/${data.drugSupplyTypeId}/drug-supply/callback`,
			{
				method: "post",
				body: data
			}
		),
	// 发放
	apiDispense: data =>
		request(
			`/project/${data.projectId}/drug-supply-type/${data.drugSupplyTypeId}/drug-supply/dispense`,
			{
				method: "post",
				body: data
			}
		),
	// 获取仓库列表
	apiGetPharmacy: data =>
		request("/pharmacy", {
			method: "get",
			params: data
		}),
	// 获取药物详情
	apiGetDrug: data =>
		request(`/project/${data.projectId}/drug-supply-type/${data.id}`, {
			params: data
		}),
	// 上传文件
	uploadFile: (data, params) =>
		request("/document/attachment/upload-extra-file", {
			method: "post",
			body: data,
			params
		})
}
