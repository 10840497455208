<template>
	<Modal
		:title="title"
		:value="visible"
		width="1200px"
		:mask-closable="false"
		@on-cancel="onCancel"
	>
		<Spin fix v-if="loading"></Spin>

		<!--表单内容-->
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<Row :gutter="32">
				<Col span="6">
					<FormItem label="耗材名称:" prop="supplyName">
						<Input
							v-model="formData.supplyName"
							placeholder="请输入耗材名称"
							:disabled="true"
						/>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="耗材编号:" prop="drugSupplyNo">
						<Input
							v-model="formData.drugSupplyNo"
							placeholder="请输入耗材编号"
						/>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="数量:" prop="amount">
						<Input v-model="formData.amount" placeholder="请输入数量" />
					</FormItem>
				</Col>

				<Col span="6">
					<FormItem label="批号:" prop="batchNo">
						<Input v-model="formData.batchNo" placeholder="请输入批号" />
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="有效期:" prop="validityDate">
						<DatePicker
							type="date"
							placeholder="请输入有效期"
							format="yyyy-MM-dd"
							v-model="formData.validityDate"
							@on-change="v => (formData.validityDate = v)"
							style="width: 100%"
						></DatePicker>
					</FormItem>
				</Col>

				<Col span="6">
					<FormItem label="快递单号:" prop="expressNo">
						<Input v-model="formData.expressNo" placeholder="请输入快递单号" />
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="6">
					<FormItem label="快递单:" prop="expressFileId">
						<p class="file-name" v-if="formData.expressFileName">
							{{ formData.expressFileName }}
							<span class="delete-file" @click="deleteFile('expressFileId')"
								>&times;</span
							>
						</p>
						<Upload
							action="/"
							v-else
							:before-upload="file => getMD5(file, 'expressFileId')"
							style="display: inline-block"
						>
							<Button>上传文件</Button>
						</Upload>
					</FormItem>
				</Col>
				<Col span="6">
					<FormItem label="运送温度记录:" prop="temperatureFileId">
						<p class="file-name" v-if="formData.temperatureFileName">
							{{ formData.temperatureFileName }}
							<span class="delete-file" @click="deleteFile('temperatureFileId')"
								>&times;</span
							>
						</p>
						<Upload
							action="/"
							v-else
							style="display: inline-block"
							:before-upload="file => getMD5(file, 'temperatureFileId')"
						>
							<Button>上传文件</Button>
						</Upload>
					</FormItem>
				</Col>
			</Row>
		</Form>

		<!--操作按钮-->
		<CtmsAction>
			<Button @click="batchAdd">批量添加</Button>
			<Button @click="batchDelete">批量删除</Button>
		</CtmsAction>

		<!--生成批次记录-->
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="columns"
			:data="listData"
			:page="false"
			ref="selTable"
			@on-selection-change="selectHandle"
		></CtmsDataGrid>

		<!--操作按钮-->
		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/project/inventory/meterial"
import { formDataToMD5 } from "@/utils/util"
import { mapState } from "vuex"

const { apiAdd, apiUpdate, uploadFile } = api

export default {
	name: "Add",
	props: ["visible", "id", "drugInfo"],
	data() {
		return {
			loading: false,
			formData: {},
			title: "新增批次",
			rules: {
				supplyName: {
					required: true,
					message: "耗材名称不能为空",
					trigger: "blur"
				},
				drugSupplyNo: {
					required: true,
					message: "耗材编号不能为空",
					trigger: "blur"
				},
				batchNo: {
					required: true,
					message: "批号不能为空",
					trigger: "blur"
				},
				amount: [
					{
						required: true,
						message: "耗材数量不能为空",
						trigger: "blur"
					},
					{
						...this.rules.positiveInteger,
						message: "耗材数量必须为正整数",
						trigger: "blur"
					}
				]
			},
			originList: [],
			list: [],
			listData: [],
			columns: [
				{
					type: "selection",
					width: 60,
					align: "center"
				},
				{
					title: "耗材名称",
					key: "supplyName",
					minWidth: 100
				},
				{
					title: "耗材编号",
					key: "drugSupplyNo",
					minWidth: 100,
					render: (h, params) =>
						h("Input", {
							props: {
								value: params.row.drugSupplyNo
							},
							on: {
								"on-blur": e =>
									this.changeDrugInfo(
										e.target.value,
										"drugSupplyNo",
										params.index
									)
							}
						})
				},
				{
					title: "数量",
					key: "amount",
					minWidth: 80
				},
				{
					title: "批号",
					key: "batchNo",
					minWidth: 100,
					render: (h, params) =>
						h("Input", {
							props: {
								value: params.row.batchNo
							},
							on: {
								"on-blur": e =>
									this.changeDrugInfo(e.target.value, "batchNo", params.index)
							}
						})
				},
				{
					title: "有效期",
					key: "validityDate",
					minWidth: 160,
					render: (h, params) =>
						h("DatePicker", {
							props: {
								value: params.row.validityDate,
								format: "yyyy-MM-dd",
								clearable: false
							},
							on: {
								"on-change": value =>
									this.changeDrugInfo(value, "validityDate", params.index)
							}
						})
				},
				{
					title: "快递单号",
					key: "expressNo",
					minWidth: 150,
					render: (h, params) =>
						h("Input", {
							props: {
								value: params.row.expressNo
							},
							on: {
								"on-blur": e =>
									this.changeDrugInfo(e.target.value, "expressNo", params.index)
							}
						})
				},
				{
					title: "快递单",
					key: "expressFileName",
					minWidth: 120
				},
				{
					title: "运送温度记录",
					key: "temperatureFileName",
					minWidth: 150
				}
			],
			selectIdList: [],
			// 分页
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$refs.form.resetFields()
				this.formData = {}
				this.selectIdList = []
				this.listData = []
				this.originList = []
				this.list = []
				if (this.drugInfo) {
					Object.assign(this.formData, {
						supplyName: this.drugInfo.supplyName
					})
				}
			}
		}
	},
	methods: {
		// 批量添加
		batchAdd() {
			// 对上一次添加进行校验
			this.$refs.form.validate(valid => {
				if (valid) {
					const originList = [...this.originList]
					for (let i = 0; i < this.formData.amount; i += 1) {
						originList.push({
							...this.formData,
							supplyName: this.drugInfo.supplyName,
							drugSupplyNo: `${this.formData.drugSupplyNo}-${i + 1}`,
							amount: 1,
							drugSupplyTypeId: this.$route.query.drugId,
							id: `${new Date().getTime()}_${this.listData.length}`
						})
					}
					this.originList = originList
					this.$nextTick(() => {
						this.chunkList()
					})
					this.selectIdList = []
				}
			})
		},
		onCancel() {
			this.$emit("onCancel")
		},
		// 选中操作
		selectHandle(selection) {
			this.selectIdList = selection.map(item => item.id)
		},
		// 批量删除
		batchDelete() {
			const idList = this.selectIdList
			this.listData = this.listData.filter(
				item => idList.indexOf(item.id) === -1
			)
			this.page.current = 1
			this.$nextTick(() => {
				this.chunkList()
			})
		},
		chunkList() {
			this.page.total = this.originList.length
			this.list = this._.chunk(this.originList, this.page.pageSize)
			this.$nextTick(() => {
				this.listData = this.list[this.page.current - 1]
			})
		},
		onOk() {
			// 耗材编号不能重复
			const drugSupplyList = this._.uniqBy(this.originList, "drugSupplyNo")
			if (drugSupplyList.length !== this.originList.length) {
				this.$Message.error("耗材编号不能重复")
				return false
			}
			this.$asyncDo(async () => {
				this.loading = true
				let res = false
				if (this.id) {
					res = await apiUpdate({
						projectId: this.projectId,
						drugSupplyTypeId: this.$route.query.drugId,
						drugSupplyList
					})
				} else {
					res = await apiAdd({
						projectId: this.projectId,
						drugSupplyTypeId: this.$route.query.drugId,
						drugSupplyList
					})
				}
				if (res) {
					this.$emit("onOk")
					this.$Message.success("操作成功！")
				}
				this.loading = false
			})
			return true
		},
		// md5
		getMD5(file, type) {
			formDataToMD5(file, md5Str => {
				this.uploadFile(file, { md5: md5Str }, type)
			})
			return false
		},
		// 上传文件
		uploadFile(file, params, type) {
			this.$asyncDo(async () => {
				const fd = new FormData()
				fd.append("file", file)
				const res = await uploadFile(fd, params)
				if (res) {
					this.$Message.success("上传成功!")
					const name = type.replace("Id", "Name")
					this.$set(this.formData, type, res.data)
					this.$set(this.formData, name, file.name)
				}
			})
		},
		// 获取图片
		getPreviewImg(fileId) {
			return `${this.$baseUrl}/document/attachment/user-audit-file/${fileId}`
		},
		// 列表内单元格修改
		changeDrugInfo(value, type, index) {
			const item = Object.assign({}, this.listData[index])
			item[type] = value
			this.$set(this.listData, index, item)
		},
		// 删除上传的文件
		deleteFile(type) {
			const name = type.replace("Id", "Name")
			this.$set(this.formData, type, "")
			this.$set(this.formData, name, "")
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 292
		}),
		projectId() {
			return this.$route.params.projectId || this.$route.query.projectId
		}
	}
}
</script>

<style scoped lang="less">
.file-name {
	position: relative;
	line-height: 20px;
	height: 20px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	.delete-file {
		color: #fff;
		background-color: #999;
		position: absolute;
		right: 0;
		top: 0;
		display: block;
		width: 20px;
		height: 20px;
		z-index: 2;
		text-align: center;
		border-radius: 50%;
		font-size: 14px;
		cursor: pointer;
		&:hover {
			background-color: red;
		}
	}
}
</style>
